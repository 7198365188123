import React from "react";

import ImageNotFound from "../../../assets/images/broken-pipe.svg";

import "./notFoundTemplate.scss";

const NotFoundTemplate = () => {
  return (
    <div className="t-not-found">
      <img
        className="t-not-found__image"
        src={ImageNotFound}
        alt="Página no encontrada"
      />
      <h1 className="t-not-found__title">
        Página no encontrada
      </h1>
      <p className="t-not-found__desc">
        No pudimos encontrar la página que buscas.<br />
        Prueba verificando la url que ingresaste, usando el menú superior o
        las opciones al final de esta página.
      </p>
    </div>
  )
}

export default NotFoundTemplate;