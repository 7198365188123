import React from "react";
import Layout from "../components/organisms/layout/layout";
import NotFoundTemplate from "../components/templates/not-found-template/notFoundTemplate";

const NotFound = () => {
  return (
    <Layout title="Dicar | Página no encontrada">
      <NotFoundTemplate />
    </Layout>
  )
}

export default NotFound;